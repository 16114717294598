<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Badge from 'primevue/badge'
import Checkbox from 'primevue/checkbox'
import { useToast } from 'primevue/usetoast'
import { type Lesson } from '@/types'
import { axiosInstance } from '@/http';

const toast = useToast()

interface Props {
  userId: number
}

const store = useAuthStore()
const props = defineProps<Props>()
const lessonData = ref([])

async function loadStats(userId: number) {
  if (!userId) {
    return
  }
  axiosInstance
    .get(`/lessonStats/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      lessonData.value = response.data
    })
}

onMounted(() => {
  loadStats(props.userId)
})

function toggleLearn(data: Lesson, toggle: boolean) {
  axiosInstance
    .post(
      '/excerciseToggle/',
      { userId: props.userId, lessonId: parseInt(data.id), toggle, fromAdmin: true },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      if (response.status === 200) {
        toast.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Статус урока обновлен',
          life: 3000
        })
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Ошибка обновления',
          life: 3000
        })
      }
    })
}
</script>

<template>
  <main>
    <DataTable :value="lessonData">
      <Column field="title" header="Название"></Column>
      <Column field="descr" header="Описание"></Column>
      <Column field="visited" header="Просмотрен">
        <template #body="slotProps">
          <!-- <Badge
            icon="pi pi-eye"
            :value="slotProps.data.visited"
            :severity="slotProps.data.visited ? 'success' : 'danger'"
          ></Badge> -->
          <i
            v-if="slotProps.data.visited"
            class="pi pi-eye"
            style="font-size: 2rem; color: #34d399"
          />
          <i
            v-if="!slotProps.data.visited"
            class="pi pi-eye-slash"
            style="font-size: 2rem; color: #27272a"
          />
        </template>
      </Column>
      <Column field="passedTest" header="Упражнение пройдено">
        <template #body="slotProps">
          <!-- <Badge
            :value="slotProps.data.passedTest"
            :severity="slotProps.data.passedTest ? 'success' : 'danger'"
          ></Badge> -->
          <!-- <ToggleSwitch v-model="slotProps.data.passedTest" /> -->
          <Checkbox
            @change="toggleLearn(slotProps.data, slotProps.data.passedTest)"
            v-model="slotProps.data.passedTest"
            :binary="true"
          />
        </template>
      </Column>
    </DataTable>
  </main>
</template>

<style scoped></style>
