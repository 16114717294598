<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { type Lesson } from '@/types'
import { axiosInstance } from '@/http';

interface Props {
  userId: number
}

const store = useAuthStore()
const props = defineProps<Props>()
const lessonData = ref<Array<Lesson>>([])

async function loadStats(userId: number) {
  if (!userId) {
    return
  }
  axiosInstance
    .get(`/lessonStats/${userId}`, {
      headers: {
        Authorization: 'Bearer ' + store.token,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      lessonData.value = response.data
    })
}

onMounted(() => {
  loadStats(props.userId)
})

function determineColor(lesson: any) {
  if (lesson.passedTest) {
    return '#059669'
  }
  if (lesson.visited) {
    return '#ca8a04'
  }
  return '#1f2937'
}
</script>

<template>
  <main>
    <div class="flex items-center justify-center">
      <span
        v-for="lesson in lessonData"
        class="flex w-4 h-4 items-center justify-center text-white rounded-full z-10 shadow-sm"
        :style="{ backgroundColor: determineColor(lesson) }"
        :title="lesson.title"
      >
        <span></span>
      </span>
    </div>
  </main>
</template>

<style scoped></style>
