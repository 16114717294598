<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import Select from 'primevue/select'
import { useToast } from 'primevue/usetoast'
import { axiosInstance } from '@/http';

const toast = useToast()

interface Props {
  data: any
}

const store = useAuthStore()
const props = defineProps<Props>()

const belts = ref([
  { name: 'Белый пояс', code: '1' },
  { name: 'Синий пояс', code: '2' },
  { name: 'Черный пояс', code: '3' }
])

const currentBelt = ref(belts.value.find((singleBelt) => singleBelt.code === props.data.belt_id))

// onMounted(() => {
//   //   loadStats(props.userId)
//   console.log(props.data)

// })

function updateBelt(selectedBelt: { value: { code: string } }) {
  axiosInstance
    .post(
      `/updateBelt`,
      {
        userId: props.data.user_id,
        beltId: selectedBelt.value.code
      },
      {
        headers: {
          Authorization: 'Bearer ' + store.token,
          'Content-Type': 'application/json'
        }
      }
    )
    .then((response) => {
      toast.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Пояс обновлен',
        life: 3000
      })
    })
}
</script>

<template>
  <main>
    <Select
      v-model="currentBelt"
      :value="currentBelt"
      :options="belts"
      optionLabel="name"
      @change="updateBelt"
      placeholder="Выбрать пояс"
      class="w-full md:w-56"
    />
  </main>
</template>

<style scoped></style>
